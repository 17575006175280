import logoSvg from './assets/logo-svg.svg';
import './App.scss';
import { useForm } from 'react-hook-form';
import { useState } from 'react';


const Logo = () => {
  return (<h3 className='uppercase'>
    <img src={(logoSvg)} height={300} width={300} alt="LogoSVG" />
  </h3>);
}
const VideoClip = (props) => {

  return (
  <div {...props}>
    <video loop autoPlay={true} muted id="clips" playsInline>
      <source src={require('./assets/animazione.mp4')} type='video/mp4' />
    </video>
  </div>);
}

function App() {

  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  const [isSent, setSent] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [hasError, setError] = useState(null)

  const jsonToFormData = (json) => {
    try {
      const data = new FormData()
  
      for (let k in json) {
        data.append(k, json[k])
      }
  
      return data
    } catch (error) {
      console.error(error)
      return null
    }
  }
  

  const onSubmit = (data)=>{
    console.log(data);
    let formApi = `/wp-json/lagana-mailing/v1/contact`;
    setLoading(true)
    setError(null)
    fetch(formApi, {
      method: "POST",
      body: jsonToFormData(data),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status !== "created") throw resp.message
        setSent(true)
      })
      .catch((error) => {
        setError(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div className="App">
      
      <section className='row-cta container'>
        <div className='colonne'>
          <div class='col'>
              <Logo />
              <h3 className='font-normal'>Il nuovo sito AL sta per arrivare.<br/>The new AL website is coming soon.</h3>
              <br/>
              <h3 className='font-normal'>
                Avvisami quando sarà online.<br/> 
                Notify me when it's ready.</h3>
                {!isSent &&
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <input type="email" placeholder='email*' {...register("email",{required: true})} />
                    {/* errors will return when field validation fails  */}
                    {errors.email && <span>This field is required</span>}

                    <input type='submit' name='submit' value={'Submit'}/>
                  </form>
                }
                {isSent && <p>Email inviata correttamente.</p>}

          </div>
          <div class='col'>
              <img src={require('./assets/BQ0A0324.png')} title='Angela lagana' width='auto' height={800} />
          </div>
        </div>
      </section>

    </div>
  );
}

export default App;